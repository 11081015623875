import * as XLSX from "xlsx"
import dayjs from "dayjs"

export const timeAgo = (dateString) => {
  const date = new Date(dateString)
  const now = new Date()

  const seconds = Math.floor((now - date) / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)

  if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} and ${days % 30} day${
      days % 30 > 1 ? "s" : ""
    } ago`
  }
  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`
  }
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`
  }
  return `${seconds} second${seconds !== 1 ? "s" : ""} ago`
}

export const formatDate = (date) => {
  return new Date(date).toISOString().substring(0, 10)
}

export const DateStringFormat = (date) => {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }

  return new Intl.DateTimeFormat("fr", options).format(date)
}

export const formattedDate = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, "0")
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const chatContentWithLinks = (content) => {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g
  const formattedContent = content.replace(
    linkRegex,
    '<a href="$2" target="_blank" class="underline font-medium">$1</a>'
  )
  const emailRegex = /(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)/g

  const contentWithEmailLinks = formattedContent.replace(
    emailRegex,
    '<a href="mailto:$1" target="_blank" class="underline font-medium">$1</a>'
  )
  return <div dangerouslySetInnerHTML={{ __html: contentWithEmailLinks }} />
}

export const dayMonthOnlyDate = (date) => {
  return date.toLocaleDateString("fr", {
    day: "numeric",
    month: "long",
    timeZone: "UTC",
  })
}

export const downloadXLSXFile = (fileName, data) => {
  const headerRow = data[0]
  const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...data.slice(1)])
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, worksheet, fileName)
  const binaryString = XLSX.write(wb, { bookType: "xlsx", type: "binary" })
  const arrayBuffer = s2ab(binaryString)
  const blob = new Blob([arrayBuffer], { type: "application/octet-stream" })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.download = `${fileName}_${dayjs().format('DD-MM-YYYY HH:mm')}.xlsx`
  link.click()
  URL.revokeObjectURL(url)
}

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
  return buf
}
