import React from "react";
import Login from "./components/Login";
import Chat from "./components/Chat";
import Analytics from "./components/Analytics";
import Vaudreuil from "./components/Vaudreuil";
import ChatPlayground from "./components/ChatPlayground";
import PrivateRoute from "./components/PrivateRoute";
import { HashRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/chat" element={<PrivateRoute component={Chat} />}></Route>
        <Route path="/admin" element={<PrivateRoute component={Chat} />}></Route>
        <Route path="/analytics" element={<PrivateRoute component={Analytics} />}></Route>
        <Route path="/chat-playground" element={<PrivateRoute component={ChatPlayground} />}></Route>
        <Route path="/vaudreuil" element={<Vaudreuil />}></Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
