import React, { useEffect, useState, useRef } from "react"
import { FaPaperclip, FaPaperPlane, FaSpinner } from "react-icons/fa"
import { GiCancel } from "react-icons/gi"
import Header from "./Header"
import axiosInstance from "../axiosInstance"
import { chatContentWithLinks, downloadXLSXFile } from "../helpers/helpers"

const ChatPlayground = () => {
  const [conversations, setConversations] = useState([])
  const [message, setMessage] = useState("")
  const [error, setError] = useState(null)
  const [file, setFile] = useState(null)
  const [clientName, setClientName] = useState('')
  const fileInputRef = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedModalType, setSelectedModalType] = useState({
    label: "GPT-3.5 Turbo",
    value: "gpt-3.5-turbo",
  })
  const dropdownRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const containerEndRef = useRef(null)

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown)
  }

  const changeModalOption = (option) => {
    setSelectedModalType(option)
    setShowDropdown(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const modalOptions = [
    { label: "GPT-3.5 Turbo", value: "gpt-3.5-turbo" },
    { label: "GPT-4 Turbo ", value: "gpt-4-turbo" },
    { label: "GPT-4", value: "gpt-4" },
    { label: "GPT-4o", value: "gpt-4o" },
    { label: "GPT 4o Mini", value: "gpt-4o-mini" },
    { label: "Claude 3 5-Sonnet", value: "claude-3-5-sonnet" },
    { label: "Claude 3 Haiku", value: "claude-3-haiku" },
    { label: "Claude 3 Opus", value: "claude-3-opus" },
    { label: "Gemini 1.5 Flash", value: "gemini-1.5-flash" },
    { label: "Gemini 1.5 Pro", value: "gemini-1.5-pro" },
  ]

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files.length) {
      const selectedFile = e.target.files[0]
      const validFileTypes = [
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]

      if (!validFileTypes.includes(selectedFile.type)) {
        setError("Only CSV or Excel files are allowed.")
        return
      }

      setError(null)
      setFile(selectedFile)
      fileInputRef.current.value = null
    }
  }

  const handleSendMessage = async () => {
    if (error) return
    if (message.trim() === "" && !file) {
      setError("Type a message or Upload a file")
      return
    }
    setError("")
    setLoading(true)
    let payload = {}
    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("role", "user")
      formData.append("modalType", selectedModalType.value)
      payload = formData
    } else {
      payload.role = "user"
      payload.modalType = selectedModalType.value
      payload.chatMessages = message
    }
    try {
      const response = await axiosInstance.post(`messages/playground`, payload)
      if (response.data && response.data.chatResponse) {
        setConversations([...conversations, ...response.data.chatResponse])
        setMessage("")
        setFile(null)
      }
    } catch (error) {
      console.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (containerEndRef) {
      containerEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [conversations])

  const handleDownloadMessages = () => {
    let data = []
    data.push(["Question", "Answer"])
    conversations.forEach((message) => {
      let messageData = [message.question, message.answer ? message.answer : ""]
      data.push(messageData)
    })
    downloadXLSXFile("Chat Logs", data)
  }

  const fetchClientName = async () => {
    try {
      const response = await axiosInstance.get(`user-data/client-name`)
      if (response.data) {
        setClientName(response.data.client.name)
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    if (!clientName) {
      fetchClientName()
    }
  },[])

  return (
    <>
      <Header />
      <div
        className='container sm:mx-auto mx-2 shadow-lg rounded-lg sm:w-4/5 xl:w-1/2 w-full mt-2'
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSendMessage()
          }
        }}
      >
        <div className='sm:px-4 sm:py-5 px-3 py-3 flex sm:gap-8 gap-6 justify-between items-center bg-white border-b-2'>
          <div className='md:font-semibold font-normal md:text-2xl text-[12px] flex items-center'>
            {clientName}
          </div>
          <div className='bg-white flex items-center justify-between'>
            <button
              onClick={handleDownloadMessages}
              className='mr-2 lg:mr-3 lg:px-4 px-2 lg:py-2 py-1 bg-[#1E293B] text-white lg:text-xl text-xs lg:font-semibold font-normal rounded-md '
            >
              Export
            </button>
            <div className='relative ' ref={dropdownRef}>
              <button
                onClick={handleDropdownClick}
                className='lg:px-4 px-2 lg:py-2 py-1 bg-[#1E293B] text-white lg:text-xl text-xs lg:font-semibold font-normal rounded-md'
              >
                {selectedModalType ? selectedModalType.label : "Select Option"}
              </button>
              {showDropdown && (
                <div className='absolute right-0 mt-2 w-56 bg-white border border-gray-300 rounded-md shadow-lg'>
                  {modalOptions.map((option, index) => (
                    <div
                      key={index}
                      className='px-4 py-1 hover:bg-[#1E293B] text-gray-700 hover:text-white cursor-pointer'
                      onClick={() => changeModalOption(option)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className='flex flex-row justify-between bg-white'
          style={{ height: "calc(100vh - 250px)" }}
        >
          <div className='w-full px-5 flex flex-col justify-between overflow-y-auto'>
            <div className='flex flex-col mt-5'>
              {conversations && conversations.length ? (
                conversations.map((message, index) => (
                  <div key={index}>
                    <div className={`flex mb-4 justify-start`}>
                      <div
                        className={`flex items-center gap-1 whitespace-pre-wrap mr-2 py-3 px-4 text-white bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl`}
                      >
                        {chatContentWithLinks(message.question)}
                      </div>
                    </div>
                    {message.answer ? (
                      <div className={`flex mb-4 justify-end`}>
                        <div
                          className={`flex items-center gap-1 whitespace-pre-wrap mr-2 py-3 px-4 bg-gray-200 rounded-br-3xl rounded-tr-3xl rounded-tl-xl`}
                        >
                          {chatContentWithLinks(message.answer)}
                        </div>
                      </div>
                    ) : (
                      message.error && (
                        <div className={`flex mb-4 justify-end`}>
                          <div
                            className={`flex items-center gap-1 whitespace-pre-wrap mr-2 py-3 px-4 bg-gray-200 rounded-br-3xl rounded-tr-3xl rounded-tl-xl`}
                          >
                            {chatContentWithLinks(message.error)}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ))
              ) : (
                <></>
              )}
              <div ref={containerEndRef}></div>
            </div>
          </div>
        </div>
        <div className='bg-gray-300'>
          <div className='p-3 bg-gray-100 border-t flex flex-col items-start relative'>
            {error && (
              <div className='text-red-500 text-sm mb-1 absolute -top-6'>
                {error}
              </div>
            )}
            <div className='flex w-full items-center'>
              <button
                onClick={() => fileInputRef.current.click()}
                className='text-gray-500 p-2 hover:bg-gray-300'
              >
                <FaPaperclip />
              </button>
              <input
                type='file'
                ref={fileInputRef}
                className='hidden'
                onChange={handleFileUpload}
              />
              {file ? (
                <div className='flex-1 ml-2 flex'>
                  {file.name}
                  <GiCancel
                    className='ml-1 cursor-pointer'
                    onClick={() => setFile(null)}
                  />
                </div>
              ) : (
                <input
                  type='text'
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value)
                    setError("")
                  }}
                  className={`flex-1 p-2 border-none bg-gray-100 focus:outline-none focus:ring-0`}
                  placeholder='Message...'
                />
              )}
              <button
                onClick={handleSendMessage}
                className={`ml-2 px-3 py-3  rounded ${
                  loading
                    ? "bg-gray-500 text-gray-100"
                    : "bg-gray-900 text-white"
                }`}
              >
                {loading ? (
                  <FaSpinner className='animate-spin' />
                ) : (
                  <FaPaperPlane />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ChatPlayground
